:host {
  display: flex;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
  display:block;
}
.ant-dropdown-menu-item .ant-menu-title-content, .ant-dropdown-menu-item .ant-menu-title-content i {
  color:#000;
}
ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding:0 10px;
}

.app-layout {
  min-height: 100vh;
}

.user-account-label {
  display:none;
}
nz-header {
  padding: 0;
  width: 100%;
  z-index: 2;
}

.app-header {
  position: relative;
  height: 64px;
  padding: 0;
  background: #001529;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .logos {
    display: flex;
    padding-right: 24px;
  }

  .logo {
    display: block;
    height: 64px;

    img {
      width: auto;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 24px;
      display: block;
      max-height: 100%;
    }
  }

  ul {
    flex-basis: 100%;
  }

  .profil {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    flex: 1 0 auto;
  }

  .menu-vertical {
    display: none;
    margin-right: 24px;
  }

  nz-drawer {
    display: none;
  }


  @media screen and (max-width: 1200px) {
    .menu-horizontal {
      display: none;
    }

    .menu-vertical {
      display: initial;
    }

    .profil {
      display: none;
    }

  }
}

nz-content {
  margin: 24px;
}

.inner-content {
  padding: 24px;
  background: #fff;
}

.user-menu {
  min-width: fit-content;

  span,
  i {
    color: #fff;
    opacity: 0.7;
  }

  &:hover {
    span,
    i {
      opacity: 1;
    }
  }
}
.ant-layout-header {
  padding:0;
}

.icon {
  margin-right: 15px;
  font-size: 20px;
}

.link {
  color: #fff;
  opacity: 0.7;

  &:hover {
    opacity: 0.9;
  }
}

.ant-menu-inline > .ant-menu-item {
  height: auto;
}

.ant-menu-submenu-inline {
  padding: 0 !important;
}
.env,
.env:hover {
  color: #fff !important;
}

::ng-deep .user-menu > ul {
  max-height: 80vh;
  overflow-y: scroll;
}
